import React from "react";

function Privacy() {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-2xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">
        Jeremy's Live built the Jeremy's Live app as a Free app. This SERVICE is
        provided by Jeremy's Live at no cost and is intended for use as is.
      </p>
      <p className="mb-4">
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone decided
        to use our Service.
      </p>
      <p className="mb-4">
        If you choose to use our Service, then you agree to the collection and use
        of information in relation to this policy. The Personal Information that we
        collect is used for providing and improving the Service. We will not use or
        share your information with anyone except as described in this Privacy
        Policy.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-3">Information Collection and Use</h2>
      <p className="mb-4">
        For a better experience, while using our Service, we collect the following
        personally identifiable information:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>First name: Used for personalizing your experience and communications</li>
        <li>Last name: Used for account identification and support services</li>
        <li>Email address: Used for account verification and important notifications</li>
        <li>Phone number: Used for account security and critical notifications</li>
      </ul>
      <p className="mb-4">
        The information that we request will be retained on our servers and used as
        described in this privacy policy.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-3">Third Party Services</h2>
      <p className="mb-4">
        The app does use third party services that may collect information used to
        identify you. Here are the specific third-party services we use:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            Google Play Services
          </a>
        </li>
        <li>
          <a
            href="https://firebase.google.com/support/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            Firebase Analytics
          </a>
        </li>
        <li>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            Google Analytics
          </a>
        </li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-3">Data Retention and Deletion Policy</h2>
      <p className="mb-4">We maintain specific data retention periods:</p>
      <ul className="list-disc pl-8 mb-4">
        <li>Account information: Retained while your account is active</li>
        <li>Usage logs: Kept for 90 days</li>
        <li>Error reports: Maintained for 30 days</li>
        <li>Inactive accounts: Deleted after 12 months of inactivity</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-3">Secure Data Handling Procedures</h2>
      <p className="mb-4">
        We implement industry-standard security measures to protect your personal information:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>Data encryption during transmission and storage</li>
        <li>Secure server infrastructure with regular updates</li>
        <li>Access controls and authentication requirements</li>
        <li>Regular security audits</li>
        <li>Automated threat detection</li>
        <li>Secure backup systems</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-3">Log Data</h2>
      <p className="mb-4">
        We want to inform you that whenever you use our Service, in a case of an
        error in the app we collect data and information (through third party
        products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol ("IP") address, device
        name, operating system version, the configuration of the app when utilizing
        our Service, the time and date of your use of the Service, and other
        statistics.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-3">Cookies</h2>
      <p className="mb-4">
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </p>
      <p className="mb-4">
        This Service does not use these "cookies" explicitly. However, the app may
        use third party code and libraries that use "cookies" to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to your
        device. If you choose to refuse our cookies, you may not be able to use
        some portions of this Service.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-3">Service Providers</h2>
      <p className="mb-4">
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>To facilitate our Service</li>
        <li>To provide the Service on our behalf</li>
        <li>To perform Service-related services</li>
        <li>To assist us in analyzing how our Service is used</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-3">Security</h2>
      <p className="mb-4">
        We value your trust in providing us your Personal Information, thus we are
        striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee its
        absolute security.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-3">Links to Other Sites</h2>
      <p className="mb-4">
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you to
        review the Privacy Policy of these websites. We have no control over and
        assume no responsibility for the content, privacy policies, or practices of
        any third-party sites or services.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-3">Children's Privacy</h2>
      <p className="mb-4">
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children under 13
        years of age. In the case we discover that a child under 13 has provided
        us with personal information, we immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that we will be
        able to do necessary actions.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-3">Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update our Privacy Policy from time to time. Thus, you are advised
        to review this page periodically for any changes. We will notify you of any
        changes by posting the new Privacy Policy on this page. These changes are
        effective immediately after they are posted on this page.
      </p>
      <p className="mb-4">This policy is effective as of December 16, 2024</p>

      <h2 className="text-xl font-bold mt-6 mb-3">Contact Us</h2>
      <p className="mb-4">
        If you have any questions or suggestions about our Privacy Policy, do not
        hesitate to contact us at admin@jeremyslive.com.
      </p>
    </div>
  );
}

export default Privacy;
